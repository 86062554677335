import React, { useEffect } from 'react'
import Layout from "../../share/layout";
import '../../../styles/font-awesome-4.7.0/css/font-awesome.css'
import Image0 from '../../../images/sonidosaudiovisuales/face.png'
import Image1 from '../../../images/sonidosaudiovisuales/artista.png'
import Image2 from '../../../images/sonidosaudiovisuales/back.png'
import styles from '.././global.css'
import '.././movil.css'

const Emilio = () => {
    useEffect(() => {
        document.body.classList.add('body-sonidos-visuales');
        return function cleanup() {
            document.body.classList.remove('body-sonidos-visuales');
        };
    }, [])
    return (
        <Layout
            title="Geografías | UPC Cultural"
            metaTitle="Geografías | UPC Cultural"
            description="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos. "
            subtitile="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            metaDescripcin="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            imgurl="//images.ctfassets.net/bv5rnjawitjg/E9aFHf11TxaEIX6JuU3vL/550f80daa53881d13f10dc95466806e2/800_x_800-1.png?h=300"
            keywords="Multimedia, Audiovisual, Luis Alvarado, Emilio Le Roux, Vered Engelhard, Luis Sanz, Paola Torres Núñez del Prado, Jonathan Castro Alejos, Lucía Beaumont."
        >
            <section id="section-content" class="section-content">
                <div class="region-inner region-content-inner">
                    <div class="face"><a href="https://www.facebook.com/upccultural1/" target="_blank"><img src={Image0} /></a></div>
                    <div id="page5" className="page page5 pdetalles" >
                        <div className="inner">
                            <span className="bg1"></span>
                            <span className="bg2"></span>
                            <span className="bglogo2"><span className="logoimg"></span></span>
                            <div className="row">
                                <div className="col cleft">
                                    <div className="video">
                                        <iframe id="video10" src="https://streamable.com/e/jqv0gp?loop=0" frameBorder="0" width="100%" height="100%" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="col cright">
                                    <div className="artista"><img src={Image1} /></div>
                                    <div className="titulo">
                                        <div className="nombre"><b>Luis Sanz</b></div>
                                        <div className="obra"><b>Obra:</b> Disociación Endémica</div>
                                    </div>
                                    <div className="detalle">
                                        <p>Sonido Sintético, Gráficos Por Computadora</p>
                                        <p>Pieza Audiovisual<br />
                                            Vídeo digital 4K<br />
                                            Sonido estéreo<br />
                                            Duración: 20’00’’</p>
                                        <p>Grabado en abril de 2022 en Val-d'Illiez, Suiza</p>
                                        <p>- Contiene imágenes parpadeantes -<br />
                                            - Reproducción en altavoces estéreo o auriculares -<br />
                                            - Reproducción en pantalla digital a 2160p, 60fps —</p>
                                        <p>Biografía:<br />
                                            Luis Sanz es un artista multidisciplinario peruano que actualmente vive y trabaja en Berna, Suiza. Sanz explora el sonido sintético, los gráficos por computadora y las experiencias audiovisuales y físicas. Su práctica incluye performance, instalación, investigación y curaduría, así como colaboración con artistas en los campos de la música experimental y las artes visuales. Su trabajo se ha presentado en festivales internacionales. Sanz participa activamente en la escena experimental suiza como solista y con Noijzu, unidad electroacústica, con la que ha realizado giras por Europa y Asia.</p>
                                        <p><a href="http://luissanz.ch" target="_blank">http://luissanz.ch</a></p>
                                    </div>
                                    <a className="btnback" href='/galeria/geografias'>
                                        <a data-page="p0"><img src={Image2} /></a>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default Emilio